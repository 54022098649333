import React from "react";
import styled from "@emotion/styled";

import {ResponsiveRender} from "@pg-design/grid";
import {borderRadius, m, mb} from "@pg-design/helpers-css";
import {FieldMessage, IRange} from "@pg-design/inputs";
import {IFormFieldProps} from "@pg-mono/form";
import {useUserDevice} from "@pg-mono/user-device";

import {getSingleChoiceBounds, isRoomInRange, updateRoomBounds} from "../../utils/room_field_data_translations";
import {SearchFilterLabel} from "./SearchFilterLabel";
import {FilterFooter} from "./SearchFiltersFooter";
import {SearchFiltersRoomsCheckbox} from "./SearchFiltersRoomsCheckbox";

interface IProps extends IFormFieldProps<string, IRange<number | "">> {
    className?: string;
    rooms?: number[];
    toggleDropdown: () => void;
    clearField: () => void;
    id?: string;
    required?: boolean;
    label?: string;
    showFooter: boolean;
    onSubmitSingle?: () => void;
    roomOptions?: {label: string; label_full: string; value: number}[];
    propertyConfiguratorInput?: boolean;
    singleChoice?: boolean;
}

export const SearchFiltersRooms = (props: IProps) => {
    const {isMobile} = useUserDevice();

    /**
     * Callback
     */

    const onChange = (name: string, value: number) => {
        if (props.singleChoice) {
            return props.onChange(name, getSingleChoiceBounds(props.value, value));
        }
        return props.onChange(name, updateRoomBounds(props.value, value, props.rooms));
    };

    const onAfterChange = () => props.onAfterChange(props.name, props.value);

    /**
     * Render
     */
    return (
        <>
            {props.label && <SearchFilterLabel htmlFor={props.id} label={props.label} />}
            <CheckboxList hasError={Boolean(props.error)} className={props.className}>
                {(props.roomOptions ?? roomOptions).map((option, index) => (
                    <li key={option.value}>
                        <SearchFiltersRoomsCheckbox
                            name={props.name}
                            value={option.value}
                            error={props.error}
                            onChange={onChange}
                            onAfterChange={onAfterChange}
                            propertyConfiguratorInput={props.propertyConfiguratorInput}
                            label={
                                <ResponsiveRender
                                    isMobile={isMobile}
                                    as="span"
                                    mobile={<span>{option.label}</span>}
                                    desktop={<span>{option.label_full}</span>}
                                />
                            }
                            isActive={isRoomInRange(props.value, option.value)}
                            isNonActive={isRoomInRange(props.value, option.value, -1)}
                            isDisabled={props.rooms?.indexOf(index + 1) === -1}
                        />
                    </li>
                ))}
            </CheckboxList>

            {props.error ? (
                <FieldMessage fieldState="error" css={[mb(2)]}>
                    {props.error}
                </FieldMessage>
            ) : null}

            {props.showFooter && (
                <FilterFooter
                    onCancel={() => {
                        props.clearField();
                        props.toggleDropdown();
                    }}
                    onSave={() => {
                        props.onSubmitSingle && props.onSubmitSingle();
                        props.onChange(props.name, props.value);
                        props.onAfterChange(props.name, props.value);
                        props.toggleDropdown();
                    }}
                />
            )}
        </>
    );
};

/**
 * Options
 */
const roomOptions = [
    {label: "1", label_full: "1", value: 1},
    {label: "2", label_full: "2", value: 2},
    {label: "3", label_full: "3", value: 3},
    {label: "4", label_full: "4", value: 4},
    {label: "5+", label_full: "5+", value: 5}
];

const CheckboxList = styled.ul<{hasError?: boolean}>`
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    background-color: #fff;
    ${({hasError}) => (hasError ? m(0) : m(0, 0, 3, 0))};

    li {
        flex-basis: 300%;
        div {
            border-top: 1px solid ${({theme, hasError}) => (hasError ? theme.colors.danger : theme.colors.gray[300])};
            border-bottom: 1px solid ${({theme, hasError}) => (hasError ? theme.colors.danger : theme.colors.gray[300])};
            border-right: 1px solid ${({theme, hasError}) => (hasError ? theme.colors.danger : theme.colors.gray[300])};
        }
    }

    li:first-of-type {
        div {
            ${borderRadius(1, 0, 0, 1)};
            border-left: 1px solid ${({theme, hasError}) => (hasError ? theme.colors.danger : theme.colors.gray[300])};
        }
    }

    li:last-of-type {
        div {
            ${borderRadius(0, 1, 1, 0)};
            border-right: 1px solid ${({theme, hasError}) => (hasError ? theme.colors.danger : theme.colors.gray[300])};
        }
    }
`;

export const Label = styled.label`
    text-align: left;
    font-size: 1.2rem;
    width: 300%;
    margin-bottom: 0.5rem;
    margin-right: 2rem;
    color: ${(props) => props.theme.colors.gray[300]};
    line-height: 1.428571429;
`;

import Bugsnag from "@bugsnag/js";

const hasErrorParameter = (error: unknown): error is {error: Error} => {
    return Object.prototype.hasOwnProperty.call(error, "error") && !!(error as {error: Error})?.error;
};

const hasMessageParameter = (error: unknown): error is {message: string; filename?: string} => {
    return Object.prototype.hasOwnProperty.call(error, "message");
};

export const notifyBugsnag = (err: unknown, errorInfo: string, errorData: unknown = {}) => {
    // notify regarding err
    if (hasErrorParameter(err)) {
        Bugsnag.notify(err.error, (event) => {
            event.addMetadata("metadata", {metaData: {errorInfo, errorData, deployment: "rp-web"}});
        });
    } else if (hasMessageParameter(err)) {
        Bugsnag.notify(
            {
                name: err.message || "Error is not defined",
                message: err.filename + ":" + err.message
            },
            (event) => {
                event.addMetadata("metadata", {errorInfo, errorData, deployment: "rp-web"});
            }
        );
    } else {
        Bugsnag.notify(
            {
                name: "unknown error",
                message: JSON.stringify(err)
            },
            (event) => {
                event.addMetadata("metadata", {metaData: {errorInfo, errorData, deployment: "rp-web"}});
            }
        );
    }
};

import {Dispatch} from "redux";

import {IRange} from "@pg-design/inputs";
import {IFetchContext} from "@pg-mono/data-fetcher";
import {toQueryValues} from "@pg-mono/form";
import {appendQueryString, catchUnknownError, getRequest, isResponseUnknownError} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IPaginatedResponse} from "../../request/IResponse";
import {otherPropertiesFilterFormFields} from "../components/PropertyDetailOtherPropertiesFilters";
import {IPropertyListProperty} from "../types/PropertyListProperty";
import {otherPropertiesFilterFormRequestTypes, otherPropertiesFilterFormTypes} from "./update_other_properties_filter_form";

interface IOtherPropertiesInOfferResponse extends IPaginatedResponse {
    results: IPropertyListProperty[];
}

export const otherPropertiesInOfferListConstraints = {
    limited_presentation: "False",
    display_type: 1,
    for_sale: "True"
};

const OTHER_PROPERTIES_IN_OFFER_PAGE_SIZE = 10;

const FETCH_OTHER_PROPERTIES_IN_OFFER = "property_detail/FETCH_OTHER_PROPERTIES_IN_OFFER";
export const EXCLUDE_CURRENT_ROOM_FILTER_OPTION = "property_detail/EXCLUDE_CURRENT_FILTER_OPTION";
export const fetchOtherPropertiesInOfferTypes = createRequestActionTypes(`${FETCH_OTHER_PROPERTIES_IN_OFFER}`);
export const resetFetchOtherPropertiesInOfferTypes = fetchOtherPropertiesInOfferTypes.reset;

export const fetchOtherPropertiesInOfferAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    dispatch({type: fetchOtherPropertiesInOfferTypes.start});

    const {offerId, propertyId} = ctx.match.params;
    const {rooms} = ctx.prevResult;

    // fetch properties with the same rooms count
    const roomRange = {lower: rooms, upper: rooms};
    const sameRoomsPropertiesResponse = await fetchOtherPropertiesInOffer(ctx.meta, offerId, propertyId, roomRange);
    if (sameRoomsPropertiesResponse.count !== 0) {
        dispatch({type: fetchOtherPropertiesInOfferTypes.success, result: sameRoomsPropertiesResponse});
        // preselect rooms filter
        dispatch({type: otherPropertiesFilterFormTypes.update, formValues: {rooms: roomRange}});
        return sameRoomsPropertiesResponse;
    }

    // if no properties found with the same rooms count, fetch all properties in parent offer
    const allPropertiesResponse = await fetchOtherPropertiesInOffer(ctx.meta, offerId, propertyId);
    dispatch({type: fetchOtherPropertiesInOfferTypes.success, result: allPropertiesResponse});
    dispatch({type: EXCLUDE_CURRENT_ROOM_FILTER_OPTION, flag: true});
    return allPropertiesResponse;
};

export const fetchOtherPropertiesInOfferOnFilterChange = (offerId: number, propertyId: number, rooms?: IRange<number | "">) => async (dispatch: Dispatch) => {
    dispatch({type: fetchOtherPropertiesInOfferTypes.start});
    try {
        const properties = await fetchOtherPropertiesInOffer({}, offerId, propertyId, rooms);
        dispatch({type: fetchOtherPropertiesInOfferTypes.success, result: properties});
    } catch (err) {
        const catchFn = catchUnknownError((error) => {
            dispatch({type: fetchOtherPropertiesInOfferTypes.error, error: error});
            dispatch({type: otherPropertiesFilterFormRequestTypes.error, error: error});
        });

        if (isResponseUnknownError(err)) {
            catchFn(err);
        }
    }
};

const fetchOtherPropertiesInOffer = (meta: IRPRequestMeta, offerId: number, propertyId: number, rooms?: IRange<number | "">) => {
    const url = appendQueryString(apiV2ListLink.property.list(Scenario.PROPERTY_LIST), {
        ...otherPropertiesInOfferListConstraints,
        offer: offerId,
        page_size: OTHER_PROPERTIES_IN_OFFER_PAGE_SIZE,
        page: 1,
        exclude_property: propertyId, //TODO: confirm if this works in v2
        ...toQueryValues(otherPropertiesFilterFormFields, {rooms})
    });

    return getRequest(meta, url).then((json: IOtherPropertiesInOfferResponse) => {
        return json;
    });
};
